import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import { AUTH_TOKEN } from "constants/AuthConstant";
import FirebaseService from "services/FirebaseService";
import UserService from "services/UserService";

export const initialState = {
  loading: false,
  user: {},
};

export const getFullMerchantData = createAsyncThunk(
  "user/getFullMerchantData",
  async () => {
    const response = await UserService.getFullMerchantData();
    return response;
  }
);

export const userSlice = createSlice({
  name: "user",
  initialState,
  reducers: {
    getUserDataSuccess: (state, action) => {
      state.loading = false;
      state.user = action.payload;
    },
  },
  extraReducers: (builder) => {
    builder
      .addCase(getFullMerchantData.pending, (state) => {
        state.loading = true;
      })
      .addCase(getFullMerchantData.fulfilled, (state, action) => {
        state.loading = false;
        state.user = action.payload;
      })
      .addCase(getFullMerchantData.rejected, (state, action) => {
        state.loading = false;
      });
  },
});

export const { getUserDataSuccess } = userSlice.actions;

export default userSlice.reducer;
