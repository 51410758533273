import fetch from "auth/FetchInterceptor";

const UserService = {};

UserService.getFullMerchantData = function (data) {
  return fetch({
    url: "/v1/merchant/getFullMerchantData",
    method: "get",
  });
};

export default UserService;
