import fetch from "auth/FetchInterceptor";

const AuthService = {};

AuthService.sendOTP = function (data) {
  return fetch({
    url: "/v1/user/sendOTPViaEmail",
    method: "post",
    data: data,
  });
};

AuthService.signInWithOTP = function (data) {
  return fetch({
    url: "/v1/user/signInWithOTPViaEmail",
    method: "post",
    data: data,
  });
};

export default AuthService;
